import React, { useState, useEffect, useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import './App.css';
import Card from './components/Card';
import { SortOption, SortDirection, Get404sResponse } from '../../shared/types';
import ContributeModal from './components/ContributeModal';
import { API_BASE_URL, DONATE_LINK } from './constants';
import Pagination from 'react-bootstrap/Pagination';
import { GoogleLogin, CredentialResponse } from '@react-oauth/google';

type Session = {
    token: string;
    email: string;
};

function App() {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [session, setSession] = useState<Session | null>(null);

    // search request
    const [sortBy, setSortBy] = useState<SortOption>('rating');
    const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(1);

    // search response
    const [data, setData] = useState<Get404sResponse>({
        previous: false,
        page: 1,
        next: false,
        results: [],
    });

    // Debounced search to prevent too many API calls while typing
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            fetch404s();
        }, 300); // 300ms delay

        return () => clearTimeout(timeoutId);
    }, [sortDirection, searchTerm, currentPage, session]);

    const fetch404s = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const params = new URLSearchParams({
                sortDirection: sortDirection,
                page: currentPage.toString(),
                searchTerm: searchTerm,
            });

            const response = await fetch(`${API_BASE_URL}/get-404s?${params}`, {
                headers: {
                    authorization: session?.token || '',
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const newData: Get404sResponse = await response.json();
            setData(newData);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An error occurred');
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    };

    // const onChangeSortBy = (e: React.FormEvent<HTMLSelectElement>) => {
    //     setSortBy(e.currentTarget.value as SortOption);
    //     setCurrentPage(1);
    // };

    const onChangeAscDec = (e: React.FormEvent<HTMLSelectElement>) => {
        setSortDirection(e.currentTarget.value as SortDirection);
        setCurrentPage(1);
    };

    const onGoogleLoginSuccess = async (res: CredentialResponse) => {
        console.log('Google login success');
        const response = await fetch(`${API_BASE_URL}/auth/google?`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ googleToken: res.credential }),
        });
        if (!response.ok) {
            console.log('failed to authenticate with backend');
            return;
        }

        setSession(await response.json());
    };

    const signOut = () => {
        setSession(null);
    };

    return (
        <>
            <header className='header'>
                <div style={{ flex: 1 }}>
                    <Button
                        className='header-button'
                        onClick={() => window.open(DONATE_LINK, '_blank', 'noopener,noreferrer')}
                    >
                        Buy me Tacos
                    </Button>
                </div>
                Which 404 pages are cool and which ones suck?
                <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'center' }}>
                    {session === null ? (
                        <GoogleLogin
                            onSuccess={onGoogleLoginSuccess}
                            onError={() => console.log('failed Google login')}
                        />
                    ) : (
                        <>
                            <div className='signed-in'>{session.email}</div>
                            <Button className='sign-out-button' onClick={signOut}>
                                Sign Out
                            </Button>
                        </>
                    )}
                    <Button className='header-button' onClick={() => setIsModalOpen(true)}>
                        Add a 404
                    </Button>
                </div>
            </header>

            <main style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Form style={{ display: 'flex', padding: 4, width: '41rem', justifyContent: 'start' }}>
                    <Form.Group style={{ marginRight: 'auto' }}>
                        <Form.Control placeholder='Search' value={searchTerm} onChange={handleSearch} />
                    </Form.Group>
                    {/* <Form.Group>
                        <Form.Select value={sortBy} onChange={onChangeSortBy}>
                            <option value='random'>Random</option>
                            <option value='rating'>Rating</option>
                            <option value='recent'>Recent</option>
                        </Form.Select>
                    </Form.Group> */}
                    <Form.Group>
                        <Form.Select value={sortDirection} onChange={onChangeAscDec} disabled={sortBy === 'random'}>
                            <option value='desc'>Descending</option>
                            <option value='asc'>Ascending</option>
                        </Form.Select>
                    </Form.Group>
                </Form>
                {isLoading
                    ? 'loading...'
                    : data.results.map((page404, index) => (
                          <Card key={index} page404={page404} token={session?.token}></Card>
                      ))}

                {data.results.length !== 0 && (
                    <Pagination>
                        <Pagination.Prev disabled={!data.previous} onClick={() => setCurrentPage(currentPage - 1)} />
                        <Pagination.Item>{currentPage}</Pagination.Item>
                        <Pagination.Next disabled={!data.next} onClick={() => setCurrentPage(currentPage + 1)} />
                    </Pagination>
                )}

                {!isLoading && data.results.length === 0 && 'no results'}
            </main>

            <ContributeModal show={isModalOpen} onHide={() => setIsModalOpen(false)} />
        </>
    );
}

export default App;
