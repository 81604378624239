import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { API_BASE_URL } from '../constants';
import Spinner from 'react-bootstrap/Spinner';

type Props = {
    show: boolean;
    onHide: () => void;
};

function ContributeModal({ show, onHide }: Props) {
    const [count, setCount] = useState<number | null>(null);
    const [urlInput, setUrlInput] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);

    useEffect(() => {
        fetch(API_BASE_URL + '/count')
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! status: ${res.status}`);
                }
                return res.text(); // or res.json() if sending JSON
            })
            .then((data) => setCount(Number(data)))
            .catch((err) => console.error('Error:', err));
    }, []);

    const add404 = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/add-404`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: `url=${encodeURIComponent(urlInput)}`,
            });
            if (!response.ok) {
                throw new Error(await response.text());
            }

            // Stuff that happens on success
            const domainAsAdded = await response.text();
            if (count) setCount(count + 1);
            setError(null);
            setSuccess(`Thanks! Added ${domainAsAdded}.`);
            setUrlInput('');
        } catch (error: any) {
            setError(error.message);
            setSuccess(null);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Know a creative 404 page? Add it here</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className='mb-3' controlId='formUrl'>
                        <Form.Control
                            value={urlInput}
                            onChange={(e) => setUrlInput(e.target.value)}
                            placeholder='Enter url'
                        />
                    </Form.Group>
                </Form>
                {error && <div className='text-danger'>{error}</div>}
                {success && <div className='text-success'>{success}</div>}
                {count && <div>{count} pages and counting</div>}
            </Modal.Body>
            <Modal.Footer style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button variant='secondary' onClick={onHide}>
                    Close
                </Button>
                <Button
                    variant='primary'
                    disabled={isLoading || urlInput === ''}
                    onClick={add404}
                    style={{ width: '170px' }}
                >
                    {isLoading ? (
                        <>
                            <Spinner
                                as='span'
                                animation='border'
                                size='sm'
                                role='status'
                                aria-hidden='true'
                                className='me-2'
                            />
                            Submitting...
                        </>
                    ) : (
                        'Submit'
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ContributeModal;
