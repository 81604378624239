import React, { useState, useEffect, useCallback } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { BiSolidUpvote, BiUpvote, BiSolidDownvote, BiDownvote } from 'react-icons/bi';
import { Page404 } from '../../../shared/types';
import { API_BASE_URL, OBSCURE_STRING } from '../constants';

const ARROW_SIZE = 24;

type Props = {
    page404: Page404;
    token: string | undefined; // JWT token for voting authentication
};

function Card({ page404, token }: Props) {
    // Make our own copy of vote info so we can update it locally
    const [score, setScore] = useState<number>(page404.score);
    const [userVote, setUserVote] = useState<number>(page404.userVote);

    const onClickUp = () => {
        sendVote(userVote === 1 ? 0 : 1);
    };

    const onClickDown = () => {
        sendVote(userVote === -1 ? 0 : -1);
    };

    const sendVote = async (vote: number) => {
        if (token === undefined) {
            console.log('undefined token');
            return;
        }

        try {
            const response = await fetch(`${API_BASE_URL}/vote`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', authorization: token },
                body: JSON.stringify({ url: page404.url, vote: vote }),
            });
            if (!response.ok) {
                console.log(await response.text());
                return;
            }
            setScore(score - userVote + vote);
            setUserVote(vote);
        } catch {
            console.log('send vote failed');
        }
    };

    const renderTooltip = (props: any) => {
        if (token === undefined) {
            return <Tooltip {...props}>Sign in to vote!</Tooltip>;
        } else {
            return <></>;
        }
    };

    return (
        <div className='card-404'>
            <button
                className='link-button'
                onClick={() => window.open(`http://${page404.url}/${OBSCURE_STRING}`, '_blank', 'noopener,noreferrer')}
            >
                {page404.url}
            </button>
            <OverlayTrigger placement='right' offset={[0, 20]} overlay={renderTooltip}>
                <span style={{ display: 'flex' }}>
                    <button className='arrow' onClick={onClickUp} disabled={token === undefined}>
                        {userVote === 1 ? <BiSolidUpvote size={ARROW_SIZE} /> : <BiUpvote size={ARROW_SIZE} />}
                    </button>
                    <div className='score-num'>{score}</div>
                    <button className='arrow' onClick={onClickDown} disabled={token === undefined}>
                        {userVote === -1 ? <BiSolidDownvote size={ARROW_SIZE} /> : <BiDownvote size={ARROW_SIZE} />}
                    </button>
                </span>
            </OverlayTrigger>
        </div>
    );
}

export default Card;
